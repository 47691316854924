.body {
    height: 100vh;
    background-color: white;
    padding: 24px;
}
.logo {
    width: 100%;
}

.MuiTextField-root {
    display: block;
}
